import liff from "@line/liff";
// import LIFFInspectorPlugin from "@line/liff-inspector";

document.addEventListener("DOMContentLoaded", () => {
  const myLiffId = gon.liff_id;
  // liff.use(new LIFFInspectorPlugin());
  liff
    .init({
      liffId: myLiffId,
    })
    .then(async () => {
      if (liff.isInClient()) {
        // LIFFブラウザ内で動作
      } else {
        // 外部ブラウザ内で動作
        if (liff.isLoggedIn()) {
          console.log("Logged in.");
        } else {
          liff.login();
        }
      }
    })
    .catch((err) => {
      $("#liff-content-wrapper").addClass("hidden");
    });
});
